import React, { Component } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { TranslationsContext } from '@italki/i18n';
// import AuthRoute from 'components/AuthRoute'
import router from './router'
import GlobalCtx, { GlobalCtxValue, GlobalCtxValuePropTypes } from './global_context'
import getLanguage from 'vendors/getLanguage';

export default class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      translation: {
        translations: props.translations,
        lang: getLanguage().locale,
        debug: false
      }
    }
  }

  getChildContext() {
    return GlobalCtxValue
  }

  render() {
    return (
      <TranslationsContext.Provider value={{ state: this.state.translation }}>
        <GlobalCtx.Provider>
          <BrowserRouter>
            <Switch>
              {router.map(({ path, PageComp, ...params }) => (
                <Route key={path} path={path} exact component={PageComp} {...params} />
                // <AuthRoute key={path} path={path} exact component={PageComp} {...params} />
              ))}
            </Switch>
          </BrowserRouter>
        </GlobalCtx.Provider>
      </TranslationsContext.Provider>
    )
  }
}

App.childContextTypes = GlobalCtxValuePropTypes
