import FeatureToggle from '@italki/feature-toggle';

export const FEATURES = {
  AI_HOMEWORK: 'ai_homework',
};

const settings = Object.keys(FEATURES).map(i => FEATURES[i]);
const ft = new FeatureToggle({
  baseUrl: process.env.REACT_APP_API_WEB_BASE || 'https://api.italki.com',
  settings,
});

export default ft;
