import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import getLanguage from './getLanguage';
import { isEmpty } from './utils';

// 语言类型
const lan = getLanguage().translation;

// 语言包
let _t = {};

// 加载状态
let loaded = false;
let loadedCallback = () => void 0;

const loadDefaultLan = () =>
  import(/* webpackChunkName: "i18n" */ `../i18n/${lan}.json`).then(data => {
    _t = data;
  });
/**
 * 加载语言包
 */
axios
  .get(`${process.env.REACT_APP_TRANSLATE_API}${lan}.json`, { timeout: 2000 })
  .then(response => {
    if (response.data) {
      _t = response.data;
    } else {
      throw Error(`fetch language failed: ${lan}`);
    }
  })
  .catch(() => loadDefaultLan())
  .finally(() => {
    loaded = true;
    loadedCallback();
  });

export default () =>
  new Promise(resolve => {
    if (loaded) {
      return resolve(_t);
    } else {
      loadedCallback = () => resolve(_t);
    }
  });

/**
 * 翻译
 * @param {*} id
 * @param  {...any} params
 * @returns {string}
 */
export const tt = (id, defaultMessage = '', values = {}) => {
  const defaultMessageAsString = typeof defaultMessage === 'string';
  let text = _t?.[id] || (defaultMessageAsString ? defaultMessage : id) || '';

  if (defaultMessageAsString) {
    if (!isEmpty(values)) {
      const reg1 = new RegExp('(\\{\\w+\\})', 'g');
      let _text = text.split(reg1);
      for (let k in values) {
        const reg2 = new RegExp('\\{' + k + '\\}');
        _text = _text.map(t => {
          if (reg2.test(t)) {
            return values[k];
          }
          return t;
        });
      }
      text = _text.join('');
    }
  } else {
    if (!isEmpty(defaultMessage)) {
      const reg1 = new RegExp('(\\{\\w+\\})', 'g');
      let _text = text.split(reg1);
      for (let k in defaultMessage) {
        const reg2 = new RegExp('\\{' + k + '\\}');
        _text = _text.map(t => {
          if (reg2.test(t)) {
            return defaultMessage[k];
          }
          return t;
        });
      }
      text = _text.join('');
    }
  }

  return text;
};

/**
 * translation's react Component
 */
export class FormattedMessage extends React.PureComponent {
  render() {
    const { id, defaultMessage = '', values = {} } = this.props;

    return tt(id, defaultMessage, values);
  }
}
FormattedMessage.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
  defaultMessage: PropTypes.string,
};

const MONTH_ENUMS = [
  'AF083', // "Jan",
  'AF084', // "Feb",
  'AF085', // "Mar",
  'AF086', // "Apr",
  'AF087', // "May",
  'AF088', // "Jun",
  'AF089', // "Jul",
  'AF090', // "Aug",
  'AF091', // "Sep",
  'AF092', // "Oct",
  'AF093', // "Nov",
  'AF094', // "Dec",
];
export function translateMonth(month = 0) {
  return isNaN(month) ? '' : tt(MONTH_ENUMS[month] || '');
}
const WEEKDAY_ENUMS = [
  'C0916', // "Sun",
  'C0910', // "Mon",
  'C0911', // "Tue",
  'C0912', // "Wed",
  'C0913', // "Thu",
  'C0914', // "Fri",
  'C0915', // "Sat",
];
export function translateWeekday(weekday = 0) {
  return tt(WEEKDAY_ENUMS[weekday] || '');
}
