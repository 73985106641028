import Cookies from 'js-cookie'

let translation;
let locale;
let hl;

hl = Cookies.get('hl') || (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage
const _hl = hl.toLowerCase()

if (_hl.indexOf('en') !== -1) {
  translation = 'en_us';
  locale = 'en';
} else if (_hl.indexOf('cn') !== -1) {
  translation = 'zh_cn';
  locale = 'zh-Hans';
} else if (_hl.indexOf('tw') !== -1) {
  translation = 'zh_tw';
  locale = 'zh-Hant';
} else if (_hl.indexOf('es') !== -1) {
  translation = 'es';
  locale = 'es';
} else if (_hl.indexOf('fr') !== -1) {
  translation = 'fr';
  locale = 'fr';
} else if (_hl.indexOf('pt') !== -1) {
  translation = 'pt';
  locale = 'pt';
} else if (_hl.indexOf('de') !== -1) {
  translation = 'de';
  locale = 'de';
} else if (_hl.indexOf('ja') !== -1) {
  translation = 'ja';
  locale = 'ja';
} else if (_hl.indexOf('ko') !== -1) {
  translation = 'ko';
  locale = 'ko';
} else if (_hl.indexOf('it') !== -1) {
  translation = 'it';
  locale = 'it';
} else if (_hl.indexOf('ru') !== -1) {
  translation = 'ru';
  locale = 'ru';
} else if (_hl.indexOf('ar') !== -1) {
  translation = 'ar';
  locale = 'ar';
} else if (_hl.indexOf('nl') !== -1) {
  translation = 'nl';
  locale = 'nl';
} else if (_hl.indexOf('pl') !== -1) {
  translation = 'pl';
  locale = 'pl';
} else if (_hl.indexOf('th') !== -1) {
  translation = 'th';
  locale = 'th';
} else if (_hl.indexOf('tr') !== -1) {
  translation = 'tr';
  locale = 'tr';
} else if (_hl.indexOf('vi') !== -1) {
  translation = 'vi';
  locale = 'vi';
} else if (_hl.indexOf('cs') !== -1) {
  translation = 'cs';
  locale = 'cs';
} else {
  if (/\bitalki\.cn\b/.test(window.location.href)) {
    translation = 'zh_cn';
    locale = 'zh-Hans';
  } else {
    translation = 'en_us';
    locale = 'en';
  }
}

if (translation.indexOf('cn') !== -1) {
  hl = 'zh-cn';
} else if (translation.indexOf('tw') !== -1) {
  hl = 'zh-tw';
} else if (translation.indexOf('en') !== -1) {
  hl = 'en-us';
}

const getLanguage = () => ({ translation, locale, hl })
export default getLanguage
