import Cookies from 'js-cookie';
import getLanguage from 'vendors/getLanguage';
import uuid from 'uuid/v4';

export const getDevice = () => process.env.REACT_APP_DEVICE_ID;

export const getToken = () =>
  Cookies.get(process.env.REACT_APP_SSO_COOKIE_KEY) || Cookies.get('kp.token');

export const getBrowserKey = () => {
  let browser_key = '';
  const _value = Cookies.get('kp.browser.key');
  if (!_value) {
    browser_key = uuid();
    Cookies.set('kp.browser.key', browser_key, {
      expires: 20 * 365,
      sameSite: 'lax',
      secure: true,
    });
  } else {
    browser_key = _value;
  }
  return browser_key;
};

const getApiHeaders = () => {
  const _token = getToken();
  const token = _token ? { 'X-Token': _token } : {};
  return {
    'X-Device': getDevice(),
    'X-Browser-Key': getBrowserKey(),
    ...token,
    'X-Locale': getLanguage(),
  };
};
export default getApiHeaders;
