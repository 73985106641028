import qs from 'qs';
import default_avatar from 'static/img/default-avatar.svg';

const padNum = num => {
  if (num < 10) {
    return `0${num}`;
  } else {
    return num;
  }
};

const cdnfyAvatarUrl = avatarFileName =>
  avatarFileName
    ? `${process.env.REACT_APP_AVATAR_CDN}/${avatarFileName}_Avatar.jpg`
    : default_avatar;

// const lessonTitle = lesson => {
//   let { title, type, language_learn } = lesson;

//   const {
//     TS215,
//     TS047,
//     TP999,
//     TS200,
//     ...translations
//   } = require('../i18n/en_us.json');

//   if (translations[language_learn]) {
//     language_learn = translations[language_learn];
//   }

//   if (type === 3) {
//     return `${TS215}: ${language_learn}`;
//   }

//   if (type === 4) {
//     return `${TS047}: ${language_learn}`;
//   }

//   if (!title) {
//     return `${TP999}: ${language_learn}`;
//   }

//   if (title === 'TS200') {
//     return format(TS200, language_learn);
//   }
//   return title;
// };

const omit = (obj, fields) => {
  const shallowCopy = {
    ...obj,
  };
  for (let i = 0; i < fields.length; i++) {
    const key = fields[i];
    delete shallowCopy[key];
  }
  return shallowCopy;
};

//preload image
const getImage = url => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => {
      resolve(url);
    };
    img.onerror = () => {
      reject(url);
    };
    img.src = url;
  });
};

// const uuid = ()=>{
//   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c=> {
//     let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
//     return v.toString(16);
//   })
// }

const getAvatar = fileName => {
  return getImage(cdnfyAvatarUrl(fileName));
};

const isString = str => typeof str === 'string';

const now = () => +new Date();

const calcChannelName = (a, b) => (a < b ? `${a}_${b}` : `${b}_${a}`);

const GetPicUrl = (url, params) => {
  if (!url || /^blob/.test(url)) {
    return url || '';
  }
  const index = url?.indexOf('?');
  let _url = url;
  if (index > -1) {
    const baseUrl = url.slice(0, index);
    const paramsStr = url.slice(index + 1);
    const paramsObj = qs.parse(paramsStr);
    const newParamsObj = Object.assign(paramsObj, params);
    _url = baseUrl + '?' + qs.stringify(newParamsObj);
  } else if (params) {
    _url = url + '?' + qs.stringify(params);
  }
  return _url;
};

const Round = (number, precision) => {
  return Math.round(+number + 'e' + precision) / Math.pow(10, precision);
};

const BytesToSize = bytes => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (!bytes) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i > 1) {
    return Round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  } else {
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  }
};

const GetOfsPicUrl = (url, thumbnailParams) => {
  if (!url || /^http|^blob/.test(url)) {
    return url || '';
  }
  let _url = `${process.env.REACT_APP_FILES_CDN}/${url}`;

  if (thumbnailParams) {
    _url = formatThumbnail(_url, thumbnailParams);
  }

  return _url;
};

const formatImageUrl = url => {
  if (!url || /^http/.test(url)) {
    return url || '';
  }
  let _url = `${process.env.REACT_APP_FILES_CDN}/${url}`;

  return _url;
};
const formatAudioUrl = url => {
  return formatImageUrl(url);
};

const SizeTypeMap = {
  ratio_thumbnail: '224x224',
  ratio_message: '252x252',
  ratio_fit: '252,fit',
  ratio1_1: '448x448',
  ratio1_1_big: '680x680',
  ratio16_9: '400x225',
  ratio3_1: '750x250',
};
const formatThumbnail = (url, sizeType) => {
  if (!url) {
    return '';
  }
  if (/.+\.\w+/.test(url)) {
    const idx = url.lastIndexOf('.');
    return url.substr(0, idx) + '_' + SizeTypeMap[sizeType] + url.substr(idx);
  } else {
    return url;
  }
};

const isEmpty = (key, strict) => {
  if (typeof key === 'string') {
    // key = key.replace(/(^\s*)|(\s*$)/g, '');
    if (
      key === '' ||
      key === null ||
      key === undefined ||
      (strict && (key === 'null' || key === 'undefined'))
    ) {
      return true;
    } else {
      return false;
    }
  } else if (typeof key === 'undefined') {
    return true;
  } else if (typeof key === 'object') {
    for (let i in key) {
      return false;
    }
    return true;
  } else if (typeof key === 'boolean') {
    return false;
  }
};

function continuationLoop(fn, opts) {
  opts = opts || { intervalTime: 5000, asap: true };

  let loopId = null;

  const clearLoop = () => {
    if (loopId) {
      clearTimeout(loopId);
      loopId = null;
    }
  };

  function loop() {
    loopId = setTimeout(() => {
      Promise.resolve(fn()).then(() => {
        clearLoop();
        loop();
      });
    }, opts.intervalTime);
  }

  if (opts.asap) {
    Promise.resolve(fn()).then(loop);
  } else {
    loop();
  }

  return clearLoop;
}

export {
  padNum,
  cdnfyAvatarUrl,
  // lessonTitle,
  omit,
  isString,
  getAvatar,
  now,
  calcChannelName,
  GetPicUrl,
  BytesToSize,
  GetOfsPicUrl,
  formatImageUrl,
  formatAudioUrl,
  formatThumbnail,
  isEmpty,
  continuationLoop,
};
