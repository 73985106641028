// polyfills
import 'core-js/es6/promise';
import 'core-js/es6/symbol';
import 'core-js/es6/object';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'raf/polyfill';
// common lib
import React from 'react';
import ReactDOM from 'react-dom';
import {
  init as SentryInit,
  // ErrorBoundary as SentryErrorBoundary,
  captureException as SentryCaptureException,
} from '@sentry/react';
// project import
import './index.scss';
import translation from 'vendors/translation';
import App from './App.jsx';
import ft from 'utils/features';
import getApiHeaders from 'utils/getApiHeaders';

// Sentry init
SentryInit({
  dsn: 'https://9931053c81944ab2b9b068f06cbe812c@xsentry.italki.com/7',
  release: process.env.REACT_APP_VERSION,
  environment: process.env.REACT_APP_ENV,
  allowUrls: [/italki\.com/],
});
window.onerror = function(e) {
  SentryCaptureException(e);
};

// start load page
// 1. fetch translation
// 2. render app
translation().then(async _t => {
  const featureHeaders = getApiHeaders();
  await ft.getFeatureToggle(featureHeaders);
  ReactDOM.render(
    //    <SentryErrorBoundary>
    <App translations={_t} />,
    // </SentryErrorBoundary>,
    document.getElementById('root')
  );
});
