import loadable from '@loadable/component';

export const PATH_ENUMS = {
  HOME: '/',
  ROOM: '/r/:lessonId',
  MWHITEBOARD: '/mw/:lessonId',
  DEVICES: '/devices',
};

export default [
  {
    path: PATH_ENUMS.HOME,
    noauth: true,
    component: loadable(() =>
      import(/* webpackChunkName: "PageHome" */ 'pages/Home')
    ),
  },
  {
    path: PATH_ENUMS.ROOM,
    component: loadable(() =>
      import(/* webpackChunkName: "Room" */ 'pages/Room')
    ),
  },
  {
    path: PATH_ENUMS.MWHITEBOARD,
    component: loadable(() =>
      import(/* webpackChunkName: "MWorkSpace" */ 'pages/MWorkSpace')
    ),
  },
  {
    path: PATH_ENUMS.DEVICES,
    component: loadable(() =>
      import(/* webpackChunkName: "Devices" */ 'pages/Devices')
    ),
  },
  {
    path: '*',
    noauth: true,
    component: loadable(() =>
      import(/* webpackChunkName: "PageNoFound" */ 'pages/NoFound')
    ),
  },
];
