import React from 'react'
import PropTypes from 'prop-types'
import getLanguage from 'vendors/getLanguage'

const local = getLanguage()

export const GlobalCtxValue = {
  local
}

export const GlobalCtxValuePropTypes = {
  local: PropTypes.object
}

export default React.createContext(GlobalCtxValue)
